import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Button,
} from '@nextui-org/react';
import {
  useState
} from 'react';
import ApiClient from '../ApiClient';
import Screen from '../components/Screen';


const HomeScreen = (props) => {
  const [myfile, setMyfile] = useState(null);

  const changeFileInput = (e) => {
    // using the setState method to set uploaded files to allFiles in the state object
    let files = e.target.files;
    console.log(e.target.files[0]);
    console.log(files);
    setMyfile(e.target.files[0]);
 }

  const uploadFile = () => {
    // Create a new FormData object
    let formData = new FormData();
    // Append the file to the FormData object
    formData.append('file', myfile);
    // Send the FormData object to the server
    ApiClient.post('/api/v1/examples/upload', formData).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  }

  const downloadFile = () => {
    ApiClient({
      url: `/api/v1/examples/download`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      document.body.appendChild(link);
      link.click();
    });
  }



  return (
    <Screen>
        <div className='w-full flex justify-center'>
          <div className='w-full md:w-6/12'>
            <Card className='m-4'>
              <CardHeader>
                <h1 className='text-xl'>Excel Upload</h1>
              </CardHeader>
              <Divider />
              <CardBody>
                <input type="file" onChange={changeFileInput}/>
              </CardBody>
              <Divider />
              <CardFooter>
                <Button onClick={uploadFile} >Submit</Button>
              </CardFooter>
            </Card>
            <Card className='m-4'>
              <CardHeader>
                <h1 className='text-xl'>File Download</h1>
              </CardHeader>
              <Divider />
              <CardBody>
                <Button onClick={downloadFile}>Download</Button>
              </CardBody>
            </Card>
          </div>
        </div>
    </Screen>
  );

};
export default HomeScreen;