
import { useState } from "react";
import {Tabs, Tab, Card, CardBody, Avatar, Input, Textarea} from '@nextui-org/react'

const CustomersScreen = (props) => {

  return (
    <div className="px-8 py-4">
        <h1 className="text-3xl font-bold">Customers</h1>
        <p className="text-base text-slate-500">Edit customer profile and details.</p>
        <h3 className="text-base mt-8 mb-2 ml-1 font-medium">Profile</h3>
        <Card className="mb-8">
          <CardBody className="flex flex-row">
          <Avatar isBordered src="https://i.pravatar.cc/150?u=a04258a2462d826712d" size="lg" />
          <div className="ml-4">
            <h3 className="text-base font-bold">John Doe</h3>
            <p className="text-base text-slate-500">Great Supplies Inc</p>
          </div>
          </CardBody>
        </Card>
        <div className="ml-1">
          <h3 className="text-base mt-8 font-medium">Title</h3>
          <p className="text-base text-slate-500">Enter customer's title</p>
        </div>
        <Input placeholder="Ex. Head of Sales" className="mt-2 mb-4"/>
        <div className="ml-1">
          <h3 className="text-base mt-8 font-medium">Location</h3>
          <p className="text-base text-slate-500">Enter customer's location</p>
        </div>
        <Input placeholder="Ex. San Diego, California" className="mt-2 mb-4"/>
        <div className="ml-1">
          <h3 className="text-base mt-8 font-medium">Notes</h3>
          <p className="text-base text-slate-500">Enter latest notes about customer</p>
          <Textarea placeholder="Ex. Customer is interested in our new product line." className="mt-2 mb-4"/>
        </div>
    </div>
  );

};
export default CustomersScreen;