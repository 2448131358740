import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {writeUserData} from '../utils/auth';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Card, CardBody, CardHeader, CardFooter} from "@nextui-org/react";
import ApiClient from '../ApiClient';
import {
    TbMail,
    TbLock,
  } from 'react-icons/tb';

export default function LoginModal(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginActivity, setLoginActivity] = useState(false);

    const navigate = useNavigate();

    const login = () => {
      setLoginActivity(true);
      ApiClient.post('/api/v1/auth/login', {
        email: email,
        password: password
      }).then((response) => {
        writeUserData(response.data);
        props.login();
        navigate('/');
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoginActivity(false);
      });
    }

  return (
    <>
      <Card 
        placement="top-center"
      >
              <CardHeader className="flex flex-col gap-1">Log in</CardHeader>
              <CardBody className="gap-6">
                <Input
                  autoFocus
                  endContent={
                    <TbMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="flat"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  endContent={
                    <TbLock className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="flat"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/*
                <div className="flex py-2 px-1 justify-between">
                  <Checkbox
                    classNames={{
                      label: "text-small",
                    }}
                  >
                    Remember me
                  </Checkbox>
                  <Link color="primary" href="#" size="sm">
                    Forgot password?
                  </Link>
                </div>
                  */}
                  <p className="color-">Don't have an account yet? <span onClick={props.setSignupMode} className="cursor-pointer underline">Sign Up</span> here.</p>
              </CardBody>

              <CardFooter>
                <Button color="primary" variant="flat" onClick={() => login()} disabled={loginActivity}>
                  Sign in
                </Button>
              </CardFooter>
      </Card>
    </>
  );
}
