// App.tsx or App.jsx
import {BrowserRouter ,Route, Routes} from 'react-router-dom';
import {NextUIProvider} from '@nextui-org/react';
import AccountsScreen from './screens/HomeScreen';
import LandingScreen from './screens/LandingScreen';
import SidebarScreen from './screens/SidebarScreen';
import CustomersScreen from './screens/CustomersScreen';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { deleteUserData, readUserData } from './utils/auth';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const logout = () => {
    deleteUserData()
    setIsLoggedIn(false);
    window.location.replace("/welcome");
  }

  const login = () => {
    fetchUserData();
  }

  const fetchUserData = () => {
    axios.get('/api/v1/user').then((response) => {
      setUser(response.data);
      setIsLoggedIn(true);
    }).catch((error) => {
      deleteUserData();
      setIsLoggedIn(false);
      setUser(null);
    });
  }

  useEffect(() => {
    const user = readUserData();
    if (user) {
      fetchUserData();
    } else {
      if (window.location.pathname !== "/welcome") {
        window.location.replace("/welcome");
      }
    }
  }, []);

  const sidebarItems = {
    dashboard: {
      icon: "tabler:dashboard",
      title: "Dashboard",
      path: "/",
      element: <div>Dashboard</div>
    },
    projects: {
      icon: "tabler:check",
      title: "Projects",
      path: "/projects",
      element: <div>Projects</div>
    },
    customers: {
      icon: "tabler:users",
      title: "Customers",
      path: "/customers",
      element: <CustomersScreen />
    },
    tasks: {
      icon: "tabler:list-check",
      title: "Tasks",
      path: "/tasks",
      element: <AccountsScreen />
    }
  }

  return (
    <NextUIProvider>
      <BrowserRouter>
      <Sidebar logout={logout} isLoggedIn={isLoggedIn} user={user} login={login} sidebarItems={sidebarItems}>
      <Routes>
        <Route path="/welcome" element={<LandingScreen logout={logout} isLoggedIn={isLoggedIn} user={user} login={login} />} />
        {
          Object.keys(sidebarItems).map((key) => {
            return <Route key={key} path={sidebarItems[key].path} element={sidebarItems[key].element} />
          })
        }
      </Routes>
      </Sidebar>
      </BrowserRouter>
    </NextUIProvider>
  );
}


export default App;